import React, { useState, useEffect, memo } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { Spin, Breadcrumb, Row, Col, Checkbox, Select, Button } from "antd";
import styles from "@aspen/theme/Savings.module.less";
import { InvestmentProduct, IAddInvestmentData, AUTOMATICE_INVESTMENT_CYCLE } from "@aspen/model";
import { availableSpots, addAutomaticInvestmentPlan, autoInvestDetail } from "@aspen/services";
import {
  numberToThousands,
  decimalPointNoMoreX,
  convertUSD2USDC,
  resetRate,
  getQueryValue,
  i18nUtil,
  reportEvent,
  certifiedKycLevel,
  YIELD_PATHS,
  AUTHORIZATION_TYPE,
  USER_AUTH,
  YIELD_TYPES,
  GA_EVENT_NAME,
  USER_ROLE
} from "@aspen/libs";
import {
  message,
  WithTrimInput,
  WithCoinList,
  CoinImage,
  KycGuideTips,
  Disclaimer
} from "@aspen/ui";
import { ModalAutomaticInvestment, ModalSafetyVerification } from "@aspen/widgets";

const { Option } = Select;

interface IProps {
  getCoinFullName: (coinCode: string) => string;
  showToProfileBtn?: boolean;
}

function AddInvestmentPlan(props: IProps) {
  const router = useRouter();
  const intl = i18nUtil.t();
  const location_text: string = intl["kyc.certify.location"];
  const breadcrumb_first_text: string = intl["savings.automatic.add.breadcrumb.first"];
  const breadcrumb_second_text: string = intl["savings.automatic.add.breadcrumb.second"];
  const title_text: string = intl["savings.automatic.add.title"];
  const choose_plan_text: string = intl["savings.automatic.add.choose.plan"];
  const title_amount_cycle_text: string = intl["savings.automatic.add.title.amount.cycle"];
  const available_text: string = intl["savings.automatic.add.available"];
  const amount_cycle_text: string = intl["savings.automatic.add.amount.cycle"];
  const cycle_text: string = intl["savings.automatic.add.cycle"];
  const back_text: string = intl["button.back"];
  const next_text: string = intl["button.next"];
  const success_text: string = intl["savings.automatic.add.success"];
  const fail_text: string = intl["savings.automatic.add.fail"];
  const amount_err_tips_first_text: string = intl["savings.automatic.add.error.tips.first"];
  const amount_err_tips_second_text: string = intl["savings.automatic.add.error.tips.second"];
  const amount_err_tips_third_text: string = intl["savings.automatic.add.error.tips.third"];
  const cryptocurrency_supported_text = intl["savings.automatic.add.cryptocurrency.supported"];

  const productId = router?.query?.id?.toString() ?? getQueryValue("id");
  const showKycTips = !certifiedKycLevel(1);
  const { getCoinFullName } = props;
  const submitInfo = {
    cycle: "",
    amount: "",
    fee: "",
    revenueRate: "",
    payCoin: "USD",
    rateList: []
  };

  useEffect(() => {
    getInitData();
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  // @ts-ignore
  const [info, setInfo] = useState<InvestmentProduct>(null);
  const [showSafetyVerificationModal, setShowSafetyVerificationModal] = useState<boolean>(false);
  const [available, setAvailable] = useState<number>();
  const [checkedValue, setCheckedValue] = useState<string>("");
  const [checkedDistributed, setCheckedDistributed] = useState<{ [key: string]: number } | null>(
    null
  );
  const [amount, setAmount] = useState<string>("");
  const [cycle, setCycle] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [confirmInfo, setConfirmInfo] = useState<IAddInvestmentData>(submitInfo);
  const [isTransferYield, setIsTransferYield] = useState<boolean>(false);
  const [isTransferStaking, setIsTransferStaking] = useState<boolean>(false);
  const [currency, setCurrency] = useState<string>("");

  // 初始化数据,
  const getInitData: () => void = async () => {
    getDetail();
  };

  // 获取对应币种可用余额
  const getAvaibleBalance = (currency: string) => {
    availableSpots({ currency })
      .then((res) => {
        if (res?.code == "0") {
          setAvailable(res.data?.[currency]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //获取详情
  const getDetail = () => {
    const params = {
      id: productId
    };
    autoInvestDetail(params)
      .then((res) => {
        if (res?.code == "0") {
          setInfo(res?.data);
          setCurrency(res?.data?.payOptionList?.[0]?.currency);
          getAvaibleBalance(res?.data?.payOptionList?.[0]?.currency);
          setConfirmInfo((prevInfo) => {
            prevInfo.fee = res?.data?.feeRate;
            prevInfo.revenueRate = res?.data?.revenueRate;
            prevInfo.payCoin = res?.data?.payOptionList[0]?.currency;
            return { ...prevInfo };
          });
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const checkedPlan = (e, param) => {
    if (e.target.checked) {
      setCheckedValue(param.id);
      setCheckedDistributed(param.currencyProportion);
    } else {
      setCheckedValue("");
      setCheckedDistributed(null);
    }
    setConfirmInfo((prevInfo) => {
      // @ts-ignore
      prevInfo.rateList = [...resetRate(param.currencyProportion)];
      return { ...prevInfo };
    });
  };

  //计算每种币种比例 数据结构可优化 @后端
  const getCoinRate = (rate) => {
    // @ts-ignore
    return resetRate(rate).map((item, index) => {
      return (
        <div key={index} className={styles.rateItem}>
          <div className={styles.rateLeft}>
            <CoinImage size={40} coins={item.denomination} />
            <div style={{ marginLeft: 16 }}>
              <p className={styles.coinName}>{convertUSD2USDC(item.denomination)}</p>
              <p className={styles.fullName}>{getCoinFullName(item.denomination)}</p>
            </div>
          </div>
          <p className={styles.rateNum}>{item.percent}</p>
        </div>
      );
    });
  };

  //输入金额
  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    let params = e.target.value.replace(/[^\d.]/g, "").replace(/\.{2,}/g, "."); //取数字
    const value = params.replace(/^(-)*(\d+)\.(\d{1,2}).*$/, "$1$2.$3"); //取两位
    setAmount(value);
    setConfirmInfo((prevInfo) => {
      prevInfo.amount = value;
      return { ...prevInfo };
    });
  };

  //判断金额是否符合
  const checkAmount = (param) => {
    let value = Number(param);
    const selectPayOption: any = info?.payOptionList?.find((item) => item.currency === currency);
    const max = selectPayOption?.maxAmount;
    const min = selectPayOption?.minAmount;
    const increment = selectPayOption?.incrementAmount;
    const currencyName = selectPayOption?.currency;
    if (value > Number(available)) {
      return amount_err_tips_first_text;
    }
    if (value < min) {
      return `${amount_err_tips_second_text} ${numberToThousands(
        decimalPointNoMoreX(min, 2)
      )} ${convertUSD2USDC(currencyName)}`;
    }
    if (value > max) {
      return `${amount_err_tips_third_text} ${numberToThousands(
        decimalPointNoMoreX(max, 2)
      )} ${convertUSD2USDC(currencyName)}`;
    }
    //购买金额必须是递增金额倍数
    const formatAmount = parseFloat((Number(value) * 100000000).toPrecision(12));
    const formatIncrementAmount = parseFloat((Number(increment) * 100000000).toPrecision(12));
    if (formatAmount % formatIncrementAmount != 0) {
      return i18nUtil.formatMessage(
        { id: "subscribe.amount.not.increment" },
        {
          incrementAmount: increment
        }
      );
    }
    return "success";
  };

  //修改定投频率
  const handleChangeCycle = (value: string) => {
    setCycle(value);
    setConfirmInfo((prevInfo) => {
      prevInfo.cycle = value;
      return { ...prevInfo };
    });
  };

  //点击下一步弹框
  const showOrCloseModal = () => {
    setModalVisible(!modalVisible);
    reportEvent({ moduleName: GA_EVENT_NAME.yield.stepModal });
  };

  //定投转活期理财
  const _isTransferYield = (value) => {
    setIsTransferYield(value);
  };

  //定投转staking
  const _isTransferStaking = (value) => {
    setIsTransferStaking(value);
  };

  //验证码
  const hideOK = (code: string) => {
    fetchSubscribe(code);
  };

  const handleSubscribe = () => {
    const authorizationType = localStorage.getItem(AUTHORIZATION_TYPE);
    if (
      (localStorage.getItem(USER_AUTH) === USER_ROLE.ROLE_PARTNER_CUSTOMER ||
        localStorage.getItem(USER_AUTH) === USER_ROLE.ROLE_SUB) &&
      authorizationType === "0"
    ) {
      setShowSafetyVerificationModal(true);
    } else {
      fetchSubscribe();
    }

    reportEvent({
      moduleName: GA_EVENT_NAME.dca.submitPlan,
      detailParams: {
        id: info?.id,
        nameKey: info?.nameKey,
        payOptionList: JSON.stringify(info?.payOptionList),
        type: info?.type // 计划类别
      }
    });
  };

  //提交请求
  const fetchSubscribe = (emailCode?: string) => {
    setLoading(true);
    // 查询选择的支付币种
    const selectPayOption = info?.payOptionList?.find((item) => item.currency === currency);
    const param = {
      productId: info?.id,
      amount: amount,
      period: AUTOMATICE_INVESTMENT_CYCLE[cycle].code,
      // proportionId: checkedValue,
      proportion: checkedDistributed,
      payOptionId: selectPayOption?.id ?? "",
      autoFinancing: isTransferYield,
      autoStaking: isTransferStaking,
      verifyCode: emailCode || ""
    };
    reportEvent({
      moduleName: GA_EVENT_NAME.dca.createPlan,
      detailParams: {
        ...param,
        proportion: JSON.stringify(checkedDistributed)
      }
    });
    addAutomaticInvestmentPlan(param)
      .then((res) => {
        if (res?.code == "0") {
          if (res?.data) {
            message.success(success_text, 2).then(() => {
              router.replace(YIELD_PATHS.YIELD_MY_INVESTMENT_PLAN);
            });
            setShowSafetyVerificationModal(false);
            showOrCloseModal();
          } else {
            message.error(intl?.[res?.msg] ?? fail_text);
          }
        } else {
          message.error(intl?.[res?.msg] ?? fail_text);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeCurrency = (value) => {
    setCurrency(value);
    setConfirmInfo((prevInfo) => {
      prevInfo.payCoin = value;
      return { ...prevInfo };
    });
    if (value !== currency) getAvaibleBalance(value);
  };

  return (
    <>
      <section className={styles.addInvestmentPlan}>
        <Spin spinning={loading}>
          <div className="flex">
            <Breadcrumb style={{ maxWidth: "50%" }}>
              <Breadcrumb.Item>{location_text}: </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link href={{ pathname: YIELD_PATHS.YIELD, query: { tabType: YIELD_TYPES.DCA } }}>
                  {breadcrumb_first_text}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{breadcrumb_second_text}</Breadcrumb.Item>
            </Breadcrumb>
            {showKycTips ? <KycGuideTips showToProfileBtn={props?.showToProfileBtn} /> : null}
          </div>
          <div className={styles.container}>
            <p className={styles.title}>{title_text}</p>
            <p className={styles.smallTitle}>{choose_plan_text}</p>
            <Row gutter={[60, 16]}>
              {info?.proportionList.length > 0 &&
                info?.proportionList.map((item) => {
                  return (
                    <Col span={8} key={item.id}>
                      <Checkbox
                        style={{ marginBottom: 18, fontSize: 16, color: "#fff" }}
                        checked={item.id == checkedValue}
                        onChange={(e) => checkedPlan(e, item)}>
                        {item.name}
                      </Checkbox>
                      <div className={styles.coinRateContent}>
                        {getCoinRate(item.currencyProportion)}
                      </div>
                    </Col>
                  );
                })}
            </Row>
            <p className={styles.smallTitle}>{title_amount_cycle_text}</p>
            <p className={styles.cryptoCurrency}>{cryptocurrency_supported_text}</p>
            <Select
              className={styles.selectArea}
              placeholder={intl["placeholder.select"]}
              onChange={handleChangeCurrency}
              value={currency}
              bordered={false}
              size="large">
              {info?.payOptionList?.map((item) => (
                <Option value={item?.currency} key={item?.id}>
                  {convertUSD2USDC(item?.currency)}
                </Option>
              ))}
            </Select>
            <p className={styles.balanceTitle}>{available_text}</p>
            <p className={styles.balanceValue}>
              {numberToThousands(decimalPointNoMoreX(available))} {convertUSD2USDC(currency)}
            </p>
            <div className={styles.cycleContainer}>
              <div className={styles.leftCycle}>
                <p>{amount_cycle_text}</p>
                <WithTrimInput
                  bordered={false}
                  placeholder={intl["placeholder.enter"]}
                  value={amount}
                  onChange={handleChangeAmount}
                />
                {
                  <div className={styles.limitTips}>
                    {amount != "" && checkAmount(amount) != "success" && (
                      <p>{checkAmount(amount)}</p>
                    )}
                  </div>
                }
              </div>
              <div className={styles.rightCycle}>
                <p>{cycle_text}</p>
                <Select
                  className={styles.selectArea}
                  placeholder={intl["placeholder.select"]}
                  onChange={handleChangeCycle}
                  bordered={false}
                  size="large">
                  {info?.periodList?.map((item, index) => {
                    return (
                      <Option value={item} key={index}>
                        {intl[AUTOMATICE_INVESTMENT_CYCLE[item].text]}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className={styles.btns}>
              <Button
                className={styles.actionBtn}
                style={{ width: 128, height: 40, marginRight: 16 }}
                onClick={() => {
                  router.push({
                    pathname: YIELD_PATHS.YIELD,
                    query: { tabType: YIELD_TYPES.DCA }
                  });
                }}>
                {back_text}
              </Button>
              <Button
                disabled={
                  checkedValue && amount && cycle && checkAmount(amount) == "success" ? false : true
                }
                style={{ width: 128, height: 40 }}
                onClick={showOrCloseModal}
                type="primary">
                {next_text}
              </Button>
            </div>
            {modalVisible ? (
              <ModalAutomaticInvestment
                type="add"
                loading={loading}
                visible={modalVisible}
                handleOk={handleSubscribe}
                handleCancel={showOrCloseModal}
                handleChangeIsTransferYield={_isTransferYield}
                handleChangeIsTransferStaking={_isTransferStaking}
                info={confirmInfo}
                showAutoFinancing={info?.canAutoFinancing === 1}
                showAutoStaking={info?.canAutoStaking === 1}
              />
            ) : null}
            {showSafetyVerificationModal ? (
              <ModalSafetyVerification
                confirmLoading={loading}
                visible={showSafetyVerificationModal}
                handleCancel={() => {
                  setShowSafetyVerificationModal(false);
                }}
                onOk={hideOK}
                businessType={-1}
                isCustomerValidate={true}
              />
            ) : null}
          </div>
        </Spin>
      </section>
      <Disclaimer />
    </>
  );
}

export const PageAddInvestmentPlan = WithCoinList(memo(AddInvestmentPlan));
