import React, { useState, useEffect } from "react";
import { Col, Row, Space, Spin } from "antd";
import clsx from "clsx";
import { useRouter } from "next/router";
import styles from "@aspen/theme/Intelligence.module.less";
import { message, HeadRender } from "@aspen/ui";
import {
  fecthExpertAuthorFollowed,
  fecthExpertAuthorList,
  fecthIntelligenceNews,
  fecthIntelligenceNewsDetail,
  followExpert
} from "@aspen/services";
import {
  getQueryValue,
  ONLY_MOBILE,
  reportEvent,
  i18nUtil,
  GA_EVENT_NAME,
  INTELLIGENCE_PATHS
} from "@aspen/libs";
import type {
  IExpertAuthor,
  INews,
  IReqExpertAuthorList,
  IReqIntelligenceNews
} from "@aspen/model";
import { NEWSTYPE, EVENT_ACTIONS } from "@aspen/model";
import { useAsideHeight } from "@aspen/hooks";
import {
  EmptyShareDetail,
  DetailInfo,
  NewsCard,
  ExpertItem,
  DownloadAppCard,
  JumpApp
} from "@aspen/widgets";

interface IProps {
  detailData: INews;
}

const ExpertDetail: React.FC<IProps> = (props: IProps) => {
  const [detailContent, setDetailContent] = useState<INews | null>(props.detailData);
  const [loading, setLoading] = useState<boolean>(false);
  // 该作家其它专栏文章
  const [expertList, setExpertList] = useState<INews[]>([]);
  const [expertListLoading, setExpertListLoading] = useState<boolean>(false);
  // 其它专栏作家
  const [otherExperts, setOtherExperts] = useState<IExpertAuthor[]>([]);
  const [otherExpertsLoading, setOtherExpertsLoading] = useState<boolean>(false);
  // 是否关注
  const [followed, setFollowed] = useState<boolean>(false);

  const router = useRouter();
  const id = router?.query?.id?.toString() ?? getQueryValue("id") ?? "";

  const intl = i18nUtil.t();
  const otherExpertsText = intl["intelligence.expert.detail.other.experts"];
  const moreText = intl["link.more"];

  const { ref: asideRef } = useAsideHeight();

  useEffect(() => {
    if (props.detailData) setDetailContent(props.detailData);
  }, [props.detailData]);

  useEffect(() => {
    if (!ONLY_MOBILE) {
      // 获取关注关系
      getFollowedResult();
      // 获取该专栏其它文章列表
      getOtherArticles();
      // 获取其它专栏作家
      getOthersExpertAuthor();
    }
  }, []);

  const getDetailById = () => {
    if (id) {
      setLoading(true);
      fecthIntelligenceNewsDetail({ id })
        .then((res) => {
          setDetailContent(res?.data || null);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      message.error(intl["router.query.id.incorrect.tip"]);
    }
  };

  // 获取关注关系
  const getFollowedResult = () => {
    fecthExpertAuthorFollowed({
      followId: detailContent?.authorCustomer?.id ?? ""
    }).then((res) => {
      if (res?.code == "0") {
        setFollowed(res?.data);
      } else {
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
  };

  // 获取该作家其它的文章
  const getOtherArticles = () => {
    setExpertListLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 4,
      action: "all",
      type: NEWSTYPE.expert,
      kolIds: detailContent?.authorCustomer?.id
    };
    fecthIntelligenceNews(params)
      .then((res) => {
        if (res?.code == "0") {
          setExpertList(res?.data?.rows ?? []);
        }
      })
      .finally(() => {
        setExpertListLoading(false);
      });
  };

  // 获取其他专栏作家 (精选的)
  const getOthersExpertAuthor = () => {
    setOtherExpertsLoading(true);
    const params: IReqExpertAuthorList = {
      limit: 4,
      expertsType: "CHOSEN"
    };
    fecthExpertAuthorList(params)
      .then((res) => {
        if (res?.code == "0") {
          const data =
            res?.data?.rows
              ?.filter((item) => item.id !== detailContent?.authorCustomer?.id)
              ?.slice(0, 3) || [];
          setOtherExperts(data);
        }
      })
      .finally(() => {
        setOtherExpertsLoading(false);
      });
  };

  // 关注专栏
  const handleFollowClick = () => {
    followExpert({
      action: "follow",
      followId: detailContent?.authorCustomer?.id ?? ""
    }).then((res) => {
      if (res?.code == "0") {
        getDetailById();
        getFollowedResult();
      } else {
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.expert.follow,
      detailParams: { id }
    });
  };

  // 取消关注
  const handleUnfollowClick = () => {
    const id = detailContent?.authorCustomer?.id;
    if (!id) return;
    followExpert({
      action: "unfollow",
      followId: id
    }).then((res) => {
      if (res?.code == "0") {
        getDetailById();
        getFollowedResult();
      } else {
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.expert.unfollow,
      detailParams: { id }
    });
  };

  return (
    <>
      {/* 新闻详情单独处理 */}
      <HeadRender
        title={detailContent?.title}
        description={detailContent?.summary}
        image={detailContent?.images?.[0]}
      />

      <section>
        <Row className={styles.row} gutter={16}>
          <Col flex="auto" style={{ width: "calc(100% - 340px)" }}>
            <div className={clsx(styles.expertDetail, ONLY_MOBILE && styles.app)}>
              {detailContent ? (
                <>
                  <DetailInfo
                    type="expert"
                    detailInfo={detailContent}
                    dateType="time"
                    followed={followed}
                    loading={loading}
                    handleFollowClick={handleFollowClick}
                    handleUnfollowClick={handleUnfollowClick}
                  />
                  {ONLY_MOBILE ? (
                    <JumpApp
                      event_action={EVENT_ACTIONS.expertDetail}
                      id={detailContent?.id}
                      path={INTELLIGENCE_PATHS.INTELLIGENCE_MARKET_DAILY_DETAIL}
                    />
                  ) : null}
                </>
              ) : (
                <EmptyShareDetail />
              )}
            </div>
          </Col>
          {ONLY_MOBILE || (
            <Col
              flex="340px"
              ref={asideRef}
              className={styles.detailExtraContainer}
              style={{
                position: expertListLoading || otherExpertsLoading ? "static" : "sticky"
              }}>
              <Spin spinning={expertListLoading || otherExpertsLoading}>
                <Space size={16} direction="vertical" style={{ width: 340 }}>
                  {expertList?.length > 1 && (
                    <div className={styles.expertOtherArticles}>
                      <ExpertItem
                        data={{
                          ...detailContent?.authorCustomer,
                          articles: expertList
                            .filter((item) => item.id !== detailContent?.id)
                            ?.slice(0, 3)
                        }}
                        showActions={false}
                        showSummary={false}
                      />
                    </div>
                  )}
                  <NewsCard
                    title={otherExpertsText}
                    linkText={moreText}
                    link={INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT_LIST}>
                    {otherExperts?.map((item) => (
                      <ExpertItem
                        key={item.id}
                        data={item}
                        showActions={false}
                        showArticle={false}
                      />
                    ))}
                  </NewsCard>
                  <DownloadAppCard />
                </Space>
              </Spin>
            </Col>
          )}
        </Row>
      </section>
    </>
  );
};

export const PageExpertDetail = React.memo(ExpertDetail);
