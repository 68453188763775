import React, { useState, useEffect } from "react";
import Image from "next/image";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { Row, Col, Button, Spin, Tabs, Switch, Table, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import styles from "@aspen/theme/Wallet.module.less";
import {
  deleteWithdrawAddress,
  getWithdrawAddressList,
  getWithdrawEnableWhiteInfo,
  withdrawWhiteListOnAndOff,
  withdrawWhiteList,
  withdrawNormalList
} from "@aspen/services";
import {
  IBusinessVerificationHeaderModel,
  IReqSecurityVerifyHeader,
  IWalletWithdrawAddress,
  IBindingBusinessType,
  WITHDRAW_WHITELIST_TYPE
} from "@aspen/model";
import { GA_EVENT_NAME, WALLET_PATHS, convertUSD2USDC, i18nUtil, reportEvent } from "@aspen/libs";
import { message, ModalBase } from "@aspen/ui";
import { ModalBusinessVerification, ModalResult, WithRiskReminder } from "@aspen/widgets";

interface IProps {}

export function AddressManagement(props: IProps) {
  const intl = i18nUtil.t();
  const router = useRouter();

  const tabItems = [
    {
      label: intl["wallet.withdraw.white.title.all"],
      key: WITHDRAW_WHITELIST_TYPE.all
    },
    {
      label: intl["wallet.withdraw.white.title.whitelist"],
      key: WITHDRAW_WHITELIST_TYPE.whitelist
    },
    {
      label: intl["wallet.withdraw.white.title.nonWhitelist"],
      key: WITHDRAW_WHITELIST_TYPE.nonWhitelist
    }
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [noAddress, setNoAddress] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>("");
  const [enbleWhiteState, setEnbleWhiteState] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState([]);
  const [currentItem, setCurrentItem] = useState<IWalletWithdrawAddress>();
  const [switchTipsModalVisible, setSwitchTipsModalVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [noAddressModalVisible, setNoAddressModalVisible] = useState<boolean>(false);
  const [removeModalVisible, setRemoveModalVisible] = useState<boolean>(false);
  const [reviewModalVisible, setReviewModalVisible] = useState<boolean>(false);
  const [applySuccessModalVisible, setApplySuccessModalVisible] = useState<boolean>(false);
  const [showBusinessVerification, setShowBusinessVerification] = useState<boolean>(false);
  const [showListOnAndOffVerification, setListOnAndOffVerification] = useState<boolean>(false);

  const columns = [
    {
      title: intl["wallet.withdraw.white.history.label"],
      ellipsis: true,
      width: 175,
      render: (_, record: IWalletWithdrawAddress) => (
        <span>
          {record?.remark ? record?.remark : intl["wallet.withdraw.select.address.item.title"]}
        </span>
      )
    },
    {
      title: intl["wallet.withdraw.white.title.whitelist"],
      render: (_, record: IWalletWithdrawAddress) => {
        return record?.withdrawEnable ? (
          record?.type == WITHDRAW_WHITELIST_TYPE.whitelist ? (
            <Image
              unoptimized
              alt=""
              width="18"
              height="18"
              src={require("@aspen/assets/images/addressWhitelist/certifiedIcon.webp").default}
            />
          ) : (
            <Image
              unoptimized
              alt=""
              width="18"
              height="18"
              src={require("@aspen/assets/images/addressWhitelist/noCertifiedIcon.png").default}
            />
          )
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ width: 80, color: "#f17e00" }}>
              {intl["wallet.withdraw.select.address.item.pause"]}
            </span>
            <Tooltip
              title={i18nUtil.formatMessage(
                { id: "wallet.withdraw.select.address.item.pause.tips" },
                { time: dayjs(record?.availableTime).format("MM-DD HH:mm:ss") }
              )}>
              <InfoCircleOutlined style={{ paddingLeft: "5px", cursor: "pointer" }} />
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: intl["wallet.withdraw.white.history.denomination"],
      dataIndex: "currency",
      render: (value: string) => convertUSD2USDC(value)
    },
    {
      title: intl["wallet.withdraw.white.history.address"],
      dataIndex: "address",
      width: 175,
      textWrap: "word-break"
    },
    {
      title: intl["wallet.withdraw.white.history.network"],
      dataIndex: "chainName"
    },
    {
      title: intl["wallet.withdraw.white.history.origin"],
      width: 130,
      dataIndex: "source"
    },
    {
      title: intl["column.action"],
      width: 300,
      render: (_, record: IWalletWithdrawAddress) => {
        return (
          <div>
            <Button
              style={{ marginRight: 10, width: 170 }}
              onClick={() => {
                setCurrentItem(record);
                record?.type == WITHDRAW_WHITELIST_TYPE.whitelist
                  ? handleRemoveWhite()
                  : record?.approveStatus === "UNAPPROVED"
                    ? setReviewModalVisible(true)
                    : handleApplyWhite();
              }}>
              {record?.type == WITHDRAW_WHITELIST_TYPE.whitelist
                ? intl["wallet.withdraw.white.list.remove.white.title"]
                : intl["wallet.withdraw.select.address.btn.apply"]}
            </Button>
            <Button
              onClick={() => {
                reportEvent({
                  moduleName: GA_EVENT_NAME.whithdraw.delete
                });
                setCurrentItem(record);
                setDeleteModalVisible(true);
              }}>
              {intl["button.delete"]}
            </Button>
          </div>
        );
      }
    }
  ];

  const successMapData = [
    {
      label: intl["wallet.withdraw.white.history.denomination"],
      value: convertUSD2USDC(currentItem?.currency ?? "")
    },
    {
      label: intl["wallet.withdraw.white.history.network"],
      value: currentItem?.chainName
    },
    {
      label: intl["wallet.withdraw.white.history.address"],
      value: currentItem?.address
    },
    {
      label: intl["wallet.withdraw.white.history.origin"],
      value: currentItem?.source
    },
    {
      label: intl["wallet.withdraw.white.history.label"],
      value: currentItem?.remark || intl["wallet.withdraw.select.address.item.title"]
    }
  ];
  useEffect(() => {
    // 获取白名单信息
    getWithdrawEnableWhiteInfo().then((res) => {
      if (res?.code == "0") {
        setEnbleWhiteState(res?.data?.enable);
      }
    });
  }, []);

  useEffect(() => {
    fetchListData();
  }, [currentTab]);

  const handleChangeTabs = (value: string) => {
    setCurrentTab(value);
  };

  const fetchListData = () => {
    setLoading(true);
    const param = {
      limit: 100,
      addressType: currentTab
    };
    getWithdrawAddressList(param)
      .then((res) => {
        if (res?.code == "0") {
          setDataSource(res?.data ?? []);
          if (currentTab === WITHDRAW_WHITELIST_TYPE.all && !res?.data?.length) {
            setNoAddress(true);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeSwitch = () => {
    reportEvent({
      moduleName: enbleWhiteState
        ? GA_EVENT_NAME.whithdraw.offWhite
        : GA_EVENT_NAME.whithdraw.openWhite
    });
    if (noAddress) {
      setNoAddressModalVisible(true);
      return;
    }
    setSwitchTipsModalVisible(true);
  };

  const fetchChangeSwitch = (verifyParam: IBusinessVerificationHeaderModel) => {
    setLoading(true);
    const params = {
      enable: !enbleWhiteState
    };
    let headerParams: IReqSecurityVerifyHeader = {
      businessType: IBindingBusinessType.withdrawWhiteListOnAndOff,
      smsType: 0
    };
    headerParams = Object.assign({}, headerParams, verifyParam);
    reportEvent({
      moduleName: enbleWhiteState
        ? GA_EVENT_NAME.whithdraw.confirmOffWhite
        : GA_EVENT_NAME.whithdraw.confirmOpenWhite,
      detailParams: params
    });
    withdrawWhiteListOnAndOff(params, headerParams)
      .then((res) => {
        if (res?.code == "0") {
          message.success(
            enbleWhiteState
              ? intl["wallet.withdraw.white.list.bottom.modal.off.toast"]
              : intl["wallet.withdraw.white.list.bottom.modal.on.toast"]
          );
          setEnbleWhiteState(!enbleWhiteState);
          setListOnAndOffVerification(false);
        } else {
          message.error(intl[res?.msg]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleApplyWhite = () => {
    reportEvent({ moduleName: GA_EVENT_NAME.whithdraw.whiteList });
    //申请白名单
    setShowBusinessVerification(true);
  };
  const handleRemoveWhite = () => {
    reportEvent({ moduleName: GA_EVENT_NAME.whithdraw.remove });
    setRemoveModalVisible(true);
  };
  const fetchApplyWhite = (verifyParam: IBusinessVerificationHeaderModel) => {
    setLoading(true);
    const params = {
      id: currentItem?.id ?? ""
    };
    let headerParams: IReqSecurityVerifyHeader = {
      businessType: IBindingBusinessType.withdrawWhiteList,
      smsType: 0
    };
    headerParams = Object.assign({}, headerParams, verifyParam);
    reportEvent({
      moduleName: GA_EVENT_NAME.whithdraw.applyWhite,
      detailParams: params
    });
    withdrawWhiteList(params, headerParams)
      .then((res) => {
        if (res?.code == "0") {
          setShowBusinessVerification(false);
          fetchListData();
          setApplySuccessModalVisible(true);
        } else {
          message.error(intl[res?.msg]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteAddress = () => {
    if (!currentItem) return;
    const params = {
      addressId: currentItem.id
    };
    reportEvent({
      moduleName: GA_EVENT_NAME.whithdraw.deleteAddress,
      detailParams: params
    });
    deleteWithdrawAddress(params).then((res) => {
      if (res?.code == "0") {
        fetchListData();
        message.success(intl["wallet.withdraw.white.list.delete.address.success"]);
      }
    });
  };
  const removeWhitelist = () => {
    if (!currentItem) return;
    const params = {
      id: currentItem.id
    };
    reportEvent({
      moduleName: GA_EVENT_NAME.whithdraw.removeWhite,
      detailParams: params
    });
    withdrawNormalList(params).then((res) => {
      if (res?.code == "0") {
        fetchListData();
        message.success(intl["wallet.withdraw.white.list.remove.white.success"]);
      }
    });
  };
  const renderEmptyView = () => {
    return (
      <div className={styles.emptyView}>
        <Image
          unoptimized
          alt=""
          width={"88"}
          height={"88"}
          src={require("@aspen/assets/images/addressWhitelist/noAddress.webp").default}
        />
        <p>{intl["wallet.withdraw.select.address.noAddress"]}</p>
        <div className={styles.addAddress}>
          <span
            onClick={() => {
              router.push(WALLET_PATHS.WALLET_WITHDRAW_ADD_ADDRESS);
            }}>
            {intl["wallet.withdraw.white.list.bottom.modal.noAddress.btn"]}
          </span>
        </div>
        <p>{intl["wallet.withdraw.white.list.empty.text1"]}</p>
        <p style={{ margin: 0 }}>{intl["wallet.withdraw.white.list.empty.text2"]}</p>
      </div>
    );
  };

  return (
    <section className={styles.withdrawAddressManage + " " + "customer"}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <div className={styles.topTitleArea}>
              <div className={styles.leftTitleArea}>
                <span className="title">{intl["wallet.withdraw.white.list.title"]}</span>
              </div>
              <div className={styles.rightTitleArea}>
                <Button
                  type="primary"
                  className={styles.allBtn}
                  onClick={() => {
                    reportEvent({ moduleName: GA_EVENT_NAME.whithdraw.previewHistory });
                    router.push(WALLET_PATHS.WALLET_WITHDRAW_WHITELIST_HISTORY);
                  }}>
                  {intl["wallet.withdraw.white.history.title"]}
                </Button>
                <Button
                  type="primary"
                  className={styles.allBtn}
                  onClick={() => {
                    reportEvent({ moduleName: GA_EVENT_NAME.whithdraw.previewAdd });
                    router.push(WALLET_PATHS.WALLET_WITHDRAW_ADD_ADDRESS);
                  }}>
                  {intl["wallet.withdraw.white.add.title"]}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="section">
        <Spin spinning={loading}>
          <Tabs
            items={tabItems}
            className={styles.tab}
            activeKey={currentTab}
            onChange={handleChangeTabs}
          />

          {dataSource?.length ? (
            <div className={styles.tableView}>
              <Table
                className={styles.table}
                bordered={false}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            </div>
          ) : (
            renderEmptyView()
          )}
          <div className={styles.whiteListSwitchView}>
            <div className={styles.whiteListSwitchText}>
              <p className={styles.whiteListSwitchTitle}>
                {intl["wallet.withdraw.white.list.bottom.title"]}
              </p>
              <p className={styles.whiteListSwitchContent}>
                {enbleWhiteState
                  ? intl["wallet.withdraw.white.list.bottom.content.on"]
                  : intl["wallet.withdraw.white.list.bottom.content.off"]}
              </p>
            </div>
            <Switch checked={enbleWhiteState} onChange={handleChangeSwitch} />
          </div>
        </Spin>
      </div>
      {noAddressModalVisible ? (
        <ModalBase
          buttonType="only"
          open={noAddressModalVisible}
          title={intl["wallet.withdraw.white.list.bottom.modal.noAddress.title"]}
          onOk={() => {
            setNoAddressModalVisible(false);
            router.push(WALLET_PATHS.WALLET_WITHDRAW_ADD_ADDRESS);
          }}
          okText={intl["wallet.withdraw.white.list.bottom.modal.noAddress.btn"]}
          onCancel={() => {
            setNoAddressModalVisible(false);
          }}>
          <div>{intl["wallet.withdraw.white.list.bottom.modal.noAddress.content"]}</div>
        </ModalBase>
      ) : null}
      {switchTipsModalVisible ? (
        <ModalBase
          open={switchTipsModalVisible}
          title={
            enbleWhiteState
              ? intl["wallet.withdraw.white.list.bottom.modal.off.title"]
              : intl["wallet.withdraw.white.list.bottom.modal.on.title"]
          }
          onOk={() => {
            setSwitchTipsModalVisible(false);
            setListOnAndOffVerification(true);
          }}
          okText={intl["button.confirm"]}
          onCancel={() => {
            setSwitchTipsModalVisible(false);
          }}>
          <>
            <p>
              {enbleWhiteState
                ? intl["wallet.withdraw.white.list.bottom.modal.off.content"]
                : intl["wallet.withdraw.white.list.bottom.modal.on.content"]}
            </p>
            {enbleWhiteState && (
              <div style={{ marginTop: 24, color: "rgba(255, 255, 255, 0.4)" }}>
                {intl["wallet.withdraw.white.list.bottom.modal.off.notice"]}
              </div>
            )}
          </>
        </ModalBase>
      ) : null}
      {removeModalVisible ? (
        <ModalBase
          open={removeModalVisible}
          title={intl["wallet.withdraw.white.list.remove.white.title"]}
          onOk={() => {
            reportEvent({ moduleName: GA_EVENT_NAME.whithdraw.confirmRemove });
            setRemoveModalVisible(false);
            removeWhitelist();
          }}
          okText={intl["button.confirm"]}
          onCancel={() => {
            setRemoveModalVisible(false);
          }}>
          <>
            <div>{intl["wallet.withdraw.white.list.remove.white.content"]}</div>
            <p className={styles.mainColor} style={{ margin: "10px 0px" }}>
              {currentItem?.remark
                ? currentItem.remark
                : intl["wallet.withdraw.select.address.item.title"]}
              :
            </p>
            <p className={styles.mainColor}>{currentItem?.address}</p>
          </>
        </ModalBase>
      ) : null}

      {reviewModalVisible ? (
        <ModalBase
          open={reviewModalVisible}
          buttonType="only"
          title={intl["wallet.withdraw.white.list.review.title"]}
          onOk={() => {
            setReviewModalVisible(false);
            router.push(WALLET_PATHS.WALLET_WITHDRAW_WHITELIST_HISTORY);
          }}
          okText={intl["wallet.withdraw.white.list.review.btn"]}
          onCancel={() => {
            setReviewModalVisible(false);
          }}>
          <>
            <div>{intl["wallet.withdraw.white.list.review.content"]}</div>
            <p className={styles.mainColor} style={{ margin: "10px 0px" }}>
              {currentItem?.remark
                ? currentItem.remark
                : intl["wallet.withdraw.select.address.item.title"]}
              :
            </p>
            <p className={styles.mainColor}>{currentItem?.address}</p>
          </>
        </ModalBase>
      ) : null}

      {deleteModalVisible ? (
        <ModalBase
          open={deleteModalVisible}
          title={intl["wallet.withdraw.white.list.delete.address.title"]}
          onOk={() => {
            reportEvent({ moduleName: GA_EVENT_NAME.whithdraw.confirmDelete });
            setDeleteModalVisible(false);
            deleteAddress();
          }}
          okText={intl["button.confirm"]}
          onCancel={() => {
            setDeleteModalVisible(false);
          }}>
          <div>{intl["wallet.withdraw.white.list.delete.address.content"]}</div>
        </ModalBase>
      ) : null}
      {showBusinessVerification ? (
        <ModalBusinessVerification
          confirmLoading={loading}
          visible={showBusinessVerification}
          businessType={IBindingBusinessType.withdrawWhiteList}
          confirm={(res) => {
            fetchApplyWhite(res);
          }}
          cancel={() => {
            setShowBusinessVerification(false);
          }}
        />
      ) : null}
      {showListOnAndOffVerification ? (
        <ModalBusinessVerification
          confirmLoading={loading}
          visible={showListOnAndOffVerification}
          businessType={IBindingBusinessType.withdrawWhiteListOnAndOff}
          confirm={(res) => {
            fetchChangeSwitch(res);
          }}
          cancel={() => {
            setListOnAndOffVerification(false);
          }}
        />
      ) : null}
      {applySuccessModalVisible ? (
        <ModalResult
          open={applySuccessModalVisible}
          resultType={"success"}
          onOk={() => {
            setApplySuccessModalVisible(false);
            router.push(WALLET_PATHS.WALLET_WITHDRAW_WHITELIST_HISTORY);
          }}
          okText={intl["wallet.withdraw.white.list.review.btn"]}
          onCancel={() => {
            setApplySuccessModalVisible(false);
          }}
          title={intl["wallet.withdraw.white.list.apply.success.title"]}
          text={intl["wallet.withdraw.white.list.apply.success.content"]}
          list={successMapData}
        />
      ) : null}
    </section>
  );
}

export const PageWhitelistAddressManage = WithRiskReminder(AddressManagement);
