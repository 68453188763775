import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, Button, Spin } from "antd";
import { useRouter } from "next/router";
import Link from "next/link";
import styles from "@aspen/theme/Strategy.module.less";
import {IKycLevel, IVentureListItem, COMMON_CACHE_PRIORTY_KEYS, VENTURE_TYPE, IReqVentureRedeem} from "@aspen/model";
import {redeemVenture, subscribeVenture} from "@aspen/services";
import { GA_EVENT_NAME, GA_EVENT_TAG, i18nUtil, reportEvent, VENTURES_PATHS } from "@aspen/libs";
import { useCacheDataPriority } from "@aspen/hooks";
import { WithKYCInfo, HtmlContentComponent, message } from "@aspen/ui";
import {
  Disclaimer,
  ModalSecondarySubscribe,
  ModalSharesSuccResult,
  ModalSubscribeSuccResult,
  ModalVentureSell,
  ModalPrimarySubscribe,
  SubVentureResultInfoType,
  SellVentureResultInfoType,
  ISubscribeInfoRefModel,
  ISellSubmitParamsType
} from "@aspen/widgets";

interface IProps {
  showGuideModal: (level: IKycLevel, isClose?: boolean) => boolean;
}

function PageVentureDetail(props: IProps) {
  const subResultInfoRef = useRef<SubVentureResultInfoType | null>(null);
  const sellResultInfoRef = useRef<SellVentureResultInfoType | null>(null);

  const [modalSell, setModalSell] = useState<boolean>(false);
  const [modalSellResult, setModalSellResult] = useState<boolean>(false);
  const [modalSubResultVisible, setModalSubResultVisible] = useState<boolean>(false);
  const [primarySubscribe, setPrimarySubscribe] = useState<boolean>(false);
  const [secondarySubscribe, setSecondarySubscribe] = useState<boolean>(false);


  const intl = i18nUtil.t();
  const ventures_text: string = intl["ventures.detail.strategies"];
  const details_text: string = intl["ventures.detail.details"];

  const router = useRouter();
  // 埋点用 当前的风投产品列表 是一级还是二级
  const fromTab = router.query?.tab?.toString() ?? "";
  const [info, updateInfo] = useState<IVentureListItem | null>(null);
  const { data, isLoading }: any = useCacheDataPriority({
    key: COMMON_CACHE_PRIORTY_KEYS.ventureDetail,
    params: {
      ventureInfoId: router.query?.id
    },
    useQueryExtraOptions: {
      enabled: !!router.query?.id
    }
  });

  useEffect(() => {
    if (data?.code == "0") {
      updateInfo(data?.data);
    } else {
      if (data?.msg) {
        message.error(intl[data?.msg] ?? data?.msg);
      }
    }
  }, [data?.code]);

  // 是否开始或者已经过期
  const isExpired: () => string | boolean = () => {
    const endTime = info?.endTime;
    const startTime = info?.startTime;
    const now = new Date().getTime();
    if (Number(now) > Number(endTime)) {
      return "close";
    }
    if (Number(now) < Number(startTime)) {
      return "comingSoon";
    }
    return false;
  };

  const handlePrimarySubmit = (subscribeInfo: ISubscribeInfoRefModel) => {
    if (!info?.id) return;
    const { amount } = subscribeInfo;
    subResultInfoRef.current = {
      assetType: info?.type,
      amount: amount,
      currency: info?.currency,
      type: "primary"
    };
    const subscribeParams = {
      productId: info?.id,
      amount,
    };
    reportEvent({
      moduleName: GA_EVENT_NAME.ventures.detail.submitSubscribe,
      detailParams: {
        ...subscribeParams,
        type: fromTab
      }
    });
    subscribeVenture(subscribeParams)
      .then((res) => {
        if (res?.code == "0") {
          reportEvent({
            joinedTag: GA_EVENT_TAG.Modal,
            moduleName: GA_EVENT_NAME.ventures.detail.subsResult,
            detailParams: {
              ...subscribeParams,
              type: fromTab
            }
          });
          setModalSubResultVisible(true);
        } else {
          message.error(intl["venture.modal.subscribe.error.tips"]);
        }
      })
      .finally(() => {
        setPrimarySubscribe(false);
      });
  };
  const handleSecondarySubmit = (subscribeInfo: ISubscribeInfoRefModel) => {
    if (!info?.id) return;
    const { amount, discountRate } = subscribeInfo;
    subResultInfoRef.current = {
      assetType: info?.type,
      amount,
      discountRate,
      currency: info?.currency,
      type: "secondary"
    };
    const subscribeParams = {
      productId: info?.id,
      amount,
      discountRate: discountRate ?? "",
    };
    reportEvent({
      moduleName: GA_EVENT_NAME.ventures.detail.submitSubscribe,
      detailParams: {
        ...subscribeParams,
        type: fromTab
      }
    });
    subscribeVenture(subscribeParams)
      .then((res) => {
        if (res?.code == "0") {
          reportEvent({
            joinedTag: GA_EVENT_TAG.Modal,
            moduleName: GA_EVENT_NAME.ventures.detail.subsResult,
            detailParams: {
              ...subscribeParams,
              type: fromTab
            }
          });
          setModalSubResultVisible(true);
        } else {
          message.error(intl["venture.modal.subscribe.error.tips"]);
        }
      })
      .finally(() => {
        setSecondarySubscribe(false);
      });
  };
  // 点击申购赎回
  const handleModalSell = () => {
    if (props.showGuideModal(2)) {
      if (!info?.id) return;
      setModalSell(true);
      reportEvent({
        joinedTag: GA_EVENT_TAG.Modal,
        moduleName: GA_EVENT_NAME.ventures.detail.redeemModal,
        detailParams: {
          id: info?.id,
          type: fromTab
        }
      });
    }
  };

  const handleSellSubmit = (data: ISellSubmitParamsType) => {
    const { selectVenture, discountRate, listAmount} = data;
    if (!selectVenture?.id) return;
    const params: IReqVentureRedeem = {
      productId: selectVenture?.id,
      amount: listAmount,
      discountRate: discountRate,
    }
    reportEvent({
      moduleName: GA_EVENT_NAME.ventures.submitListSecondary,
      detailParams: { ...params }
    });
    sellResultInfoRef.current = {
      isOther: false,
      projectName: selectVenture?.name ?? "",
      assetType: selectVenture?.type ?? 0,
      shares: selectVenture?.shares ?? 0,
      amount: listAmount,
      discountRate: discountRate,
      currency: selectVenture?.currency ?? ""
    }
    redeemVenture(params)
        .then((res) => {
          if (res?.code == 0) {
            setModalSellResult(true);
          } else {
            message.error(intl?.[res?.msg] ?? res?.msg);
          }
        })
        .catch((error) => {
          message.error(intl?.[error?.msg] ?? error?.msg);
        })
        .finally(() => {
          setModalSell(false);
        });
  };

  if (!info) return null;

  return (
    <div className={styles.detail}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link
            href={{
              pathname: VENTURES_PATHS.VENTURES,
              query: { currentTab: info?.type === 2 ? "secondary" : "primary" }
            }}>
            {ventures_text}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{details_text}</Breadcrumb.Item>
      </Breadcrumb>
      <section id="title">
        <p className={styles.title}>{info?.name}</p>
        <div className="flex" style={{ marginBottom: 24 }}>
          {intl[VENTURE_TYPE[info?.type]] ? (
            <div className={styles.categroy}>{intl[VENTURE_TYPE[info?.type]]}</div>
          ) : null}
          {info?.attachmentUrl && info?.attachmentName ? (
            <Button
              type={"ghost"}
              className={styles.attachment}
              onClick={() => {
                reportEvent({
                  moduleName: GA_EVENT_NAME.ventures.detail.openLink,
                  detailParams: {
                    type: "venture"
                  }
                });
                window.open(info?.attachmentUrl ?? "");
              }}>
              <span>{info?.attachmentName}</span>
            </Button>
          ) : null}
        </div>
      </section>
      <Spin spinning={isLoading}>
        <div className={styles.detailContent}>
          {/* <div
          className={styles.detailBgContent}
          dangerouslySetInnerHTML={{ __html: info?.detailInformation }}
        /> */}
          <HtmlContentComponent htmlContent={info?.detailInformation} />

          {info?.name && (
            <div className={styles.btnWrap}>
              {isExpired() ? (
                <div className={styles.subBtnWrap}>
                  <Button type="primary" disabled className={styles.actionBtn}>
                    {isExpired() === "close" ? intl["button.closed"] : intl["button.comingSoon"]}
                  </Button>
                </div>
              ) : (
                <div className={styles.subBtnWrap}>
                  <Button
                    type="primary"
                    className={styles.actionBtn}
                    onClick={() => {
                      reportEvent({
                        joinedTag: GA_EVENT_TAG.Modal,
                        moduleName: GA_EVENT_NAME.ventures.detail.submitSubscribe,
                        detailParams: {
                          id: info?.id,
                          type: fromTab
                        }
                      });
                      if (info?.shares) {
                        setSecondarySubscribe(true);
                      } else {
                        setPrimarySubscribe(true)
                      }
                    }}>
                    {info?.shares ? intl["button.interest"] : intl["button.subscribe"]}
                  </Button>
                  {info?.amount && (
                    <Button
                      type="primary"
                      className={styles.actionBtn}
                      onClick={handleModalSell}>
                      {info?.type === 0 ? intl["button.redeem"] : intl["button.list"]}
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {info?.name && <Disclaimer />}
      </Spin>
      {primarySubscribe ? (
          <ModalPrimarySubscribe
              visible={primarySubscribe}
              info={info}
              purchaseInformation={info?.purchaseInformation || ""}
              handleSubmit={handlePrimarySubmit}
              cancelModal={() => {
                setPrimarySubscribe(false);
              }}
          />
      ) : null}
      {modalSell ? (
          <ModalVentureSell
              visible={modalSell}
              cancelModal={() => {
                setModalSell(false);
              }}
              handleSubmit={handleSellSubmit}
              selectVenture={info}
          />
      ) : null}
      {modalSellResult ? (
          <ModalSharesSuccResult
              visible={modalSellResult}
              sellResultInfo={sellResultInfoRef.current}
              cancelModal={() => {
                setModalSellResult(false);
              }}
          />
      ) : null}
      {secondarySubscribe ? (
        <ModalSecondarySubscribe
          visible={secondarySubscribe}
          info={info}
          purchaseInformation={info?.purchaseInformation || ""}
          handleSubmit={handleSecondarySubmit}
          cancelModal={() => {
            setSecondarySubscribe(false);
          }}
        />
      ) : null}
      {modalSubResultVisible ? (
        <ModalSubscribeSuccResult
          visible={modalSubResultVisible}
          ventureName={info?.name}
          subResultInfo={subResultInfoRef?.current}
          cancelModal={() => {
            setModalSubResultVisible(false);
          }}
        />
      ) : null}
    </div>
  );
}

export const PageVenturesDetail = WithKYCInfo(PageVentureDetail);
