import React from "react";
import { Breadcrumb, Button, message, Spin } from "antd";
import Link from "next/link";
import Image from "next/image";
import styles from "@aspen/theme/Wallet.module.less";
import {
  WALLET_PATHS,
  GA_EVENT_NAME,
  i18nUtil,
  decimalPointNoMoreX,
  fixNumString,
  numberToThousands,
  reportEvent
} from "@aspen/libs";
import { otcApply } from "@aspen/services";
import { NumericInput, ModalBase } from "@aspen/ui";
import { OtcHistory } from "@aspen/widgets";

import otc_step1 from "@aspen/assets/images/otc_step1.webp";
import otc_step2 from "@aspen/assets/images/otc_step2.webp";
import otc_step3 from "@aspen/assets/images/otc_step3.webp";

interface IProps {}
interface IState {
  error: string;
  loading: boolean;
  amount: string;
  showConfirm: boolean;
}

const otcCurrency = "USD";

class DepositOtc extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { error: "", amount: "", loading: false, showConfirm: false };
  }

  list: Array<{ key: string; icon: any }> = [
    {
      key: "step1",
      icon: otc_step1
    },
    {
      key: "step2",
      icon: otc_step2
    },
    {
      key: "step3",
      icon: otc_step3
    }
  ];

  _changeAmount = (text) => {
    let value = fixNumString(text, 2);
    let _text = value.replace(".", "");
    let error = "";
    const maxLength = 12;
    if (_text.length > maxLength) {
      error = i18nUtil.formatMessage(
        { id: "deposit.otc.deposit.otc.input.error" },
        { maxLength: maxLength }
      );
    }
    this.setState({ amount: value, error: error });
  };

  _showLoading = (show: boolean) => {
    this.setState({ loading: show });
  };

  _showConfirm = (show: boolean) => {
    this.setState({ showConfirm: show });
  };

  handleSubmit = () => {
    const { amount } = this.state;
    const intl = i18nUtil.t();
    const { deposit_otc_success_text } = this._getText();
    //上报
    reportEvent({ moduleName: GA_EVENT_NAME.wallet.otcCkConfirm });
    this._showConfirm(false);
    this._showLoading(true);
    otcApply({
      currency: otcCurrency,
      amount: Number(amount)
    })
      .then((res) => {
        if (res?.code == "0") {
          message.success(deposit_otc_success_text);
          this._changeAmount("");
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((error) => {
        message.error(intl?.[error?.msg] ?? error?.msg);
      })
      .finally(() => {
        this._showLoading(false);
      });
  };

  render() {
    const { error, loading, amount, showConfirm } = this.state;
    const {
      location_text,
      wallet_text,
      otc_title_text,
      deposit_otc_amount_text,
      deposit_otc_please_enter_text,
      deposit_otc_submit_text,
      deposit_otc_notice_text,
      deposit_otc_notice_list_arr,
      deposit_otc_confirm_title_text,
      deposit_otc_confirm_content_text,
      deposit_otc_confirm_ok_text
    } = this._getText();
    const btnEnable = (Number(amount) || 0) > 0 && !error;

    return (
      <section className={styles.depositOtc}>
        <Spin spinning={false}>
          <Breadcrumb>
            <Breadcrumb.Item>{location_text}:</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link href={WALLET_PATHS.WALLET}>{wallet_text}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{otc_title_text}</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.otcContent}>
            <p className={styles.title}>{otc_title_text}</p>
            <div className={styles.inputLabel}>{deposit_otc_amount_text}</div>
            <div className={styles.inputAction}>
              <NumericInput
                value={amount}
                addonAfter={otcCurrency}
                onChangeEvent={this._changeAmount}
                className={styles.input}
                placeholder={deposit_otc_please_enter_text}
              />
              <Button
                type="primary"
                className={styles.submit}
                onClick={() => {
                  this._showConfirm(true);
                }}
                loading={loading}
                disabled={!btnEnable}>
                {deposit_otc_submit_text}
              </Button>
            </div>
            {error ? (
              <p className="ant-form-item-explain ant-form-item-explain-error">
                <span className="ant-form-item-explain-error">{error}</span>
              </p>
            ) : undefined}
            <div className={styles.stepLabel}>{deposit_otc_notice_text}</div>
            <div className={styles.stepContainer}>
              {this.list.map((item, index) => {
                return (
                  <div key={item.key + "-" + index} className={styles.stepItem}>
                    <div className={styles.icon}>
                      <Image unoptimized src={item.icon} alt="" width="49" height="49" />
                    </div>
                    <div className={styles.title}>{deposit_otc_notice_list_arr[index]}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <OtcHistory />
          <ModalBase
            title={deposit_otc_confirm_title_text}
            open={showConfirm}
            buttonType={"only"}
            onOk={this.handleSubmit}
            okText={deposit_otc_confirm_ok_text}
            onCancel={() => {
              this._showConfirm(false);
            }}>
            <>
              <div>{deposit_otc_confirm_content_text}</div>
              <div className={styles.modalAmount}>{`${numberToThousands(
                decimalPointNoMoreX(amount, 2)
              )} ${otcCurrency}`}</div>
            </>
          </ModalBase>
        </Spin>
      </section>
    );
  }

  _getText = () => {
    const intl = i18nUtil.t();
    const location_text: string = intl["kyc.certify.location"];
    const wallet_text: string = intl["wallet"];
    const otc_title_text: string = intl["wallet.otc.title"];
    const deposit_otc_amount_text: string = intl["deposit.otc.deposit.amount"];
    const deposit_otc_please_enter_text: string = intl["deposit.otc.deposit.please.enter"];
    const deposit_otc_submit_text: string = intl["deposit.otc.submit"];
    const deposit_otc_notice_text: string = intl["deposit.otc.deposit.notice.title"];
    const deposit_otc_notice_list_arr: Array<string> = intl["deposit.otc.deposit.notice.list"];
    const deposit_otc_success_text: Array<string> = intl["deposit.otc.deposit.confirm.successful"];
    const deposit_otc_confirm_title_text: Array<string> = intl["deposit.otc.deposit.confirm.title"];
    const deposit_otc_confirm_content_text: Array<string> =
      intl["deposit.otc.deposit.confirm.content"];
    const deposit_otc_confirm_ok_text: Array<string> = intl["deposit.otc.deposit.confirm.btn"];

    return {
      location_text,
      wallet_text,
      otc_title_text,
      deposit_otc_amount_text,
      deposit_otc_please_enter_text,
      deposit_otc_submit_text,
      deposit_otc_notice_text,
      deposit_otc_notice_list_arr,
      deposit_otc_success_text,
      deposit_otc_confirm_title_text,
      deposit_otc_confirm_content_text,
      deposit_otc_confirm_ok_text
    };
  };
}

export const PageDepositOtc = DepositOtc;
