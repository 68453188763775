import React, { useState, useEffect, useRef } from "react";
import styles from "@aspen/widgets/login/Login.module.less";
import { Form, Button, InputRef } from "antd";
import {
  message,
  WithTrimInput
} from "@aspen/ui";
import { managerLogin, managerLogout } from "@aspen/services";
import {
  PASSWORD_MAX_LENGTH,
  ASSETANALYSIS,
  USER_AUTH,
  ERROR,
  IS_AUTHENTICATED,
  USER,
  CUSTOMER_ID,
  CUSTOMER_INFO,
  IS_GOOGLE_Certified,
  LOGIN_ERROR_TIP_MSG,
  GA_EVENT_NAME,
  clearLocalStorage,
  cookieUtil,
  handleBlur,
  handleFocus,
  reportEvent,
  i18nUtil,
  getLocalStorageTokenKey,
  clearCookies,
  MANAGER_PATH,
  md5Encrypted,
  MD5_FIXED_STRING
} from "@aspen/libs";
import { useRouter } from "next/router";
import clsx from "clsx";
import { LoginBaseHandle, ErrorTip, LoginLayoutSAS } from "@aspen/widgets";

interface IProps {}

const ManagerLogin: React.FC<IProps> = (props: IProps) => {
  const intl = i18nUtil.t();

  const [accountName, setAccountName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [resError, setResError] = useState<string | null>(null);

  const emailInput: React.MutableRefObject<InputRef | null> = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const router = useRouter();

  useEffect(() => {
    emailInput?.current?.focus();
  }, [accountName]);

  useEffect(() => {
    router.prefetch(MANAGER_PATH.CUSTOMER_MANAGEMENT);

    const error = localStorage.getItem(ERROR);
    if (error) {
      message.error(intl[error]);
    }
    emailInput?.current?.focus();
    form.setFieldsValue({ email: accountName, password });

    managerLogout().finally(() => {
      clearLocalStorage();
      clearCookies();
    });
  }, []);

  const handleSubmit: () => void = async () => {
    fetchSubmit();
    reportEvent({ moduleName: GA_EVENT_NAME.login.loginService });
  };

  const fetchSubmit = async () => {
    setLoading(true);
    const params = {
      account: accountName,
      password: md5Encrypted(password, MD5_FIXED_STRING)
    };

    managerLogin(params)
      .then(async (res) => {
        if (res?.code == "0") {
          const { user_id, account_id, jwtToken, isGoogleCertified, user_roles, customer_info } =
            res?.data;
          localStorage.setItem(
            USER_AUTH,
            user_roles ? user_roles.join(",").toLocaleLowerCase() : ""
          );
          // FIXME: 显示隐藏资产分析入口
          localStorage.setItem(ASSETANALYSIS, "true");
          localStorage.setItem(getLocalStorageTokenKey(location.origin), jwtToken ?? "");
          cookieUtil.set(getLocalStorageTokenKey(location.host), jwtToken ?? "");
          localStorage.setItem(IS_AUTHENTICATED, "true");
          localStorage.setItem(USER, accountName);
          localStorage.setItem(CUSTOMER_ID, account_id ?? user_id);
          cookieUtil.set(USER, accountName);
          cookieUtil.set(CUSTOMER_ID, account_id ?? user_id);
          localStorage.setItem(CUSTOMER_INFO, customer_info);
          localStorage.setItem(IS_GOOGLE_Certified, Number(isGoogleCertified).toString());
          router.push(MANAGER_PATH.CUSTOMER_MANAGEMENT);
        } else {
          /*错误判断包含：
           * 1. 账号未注册，服务器端判断返回
           * 2. 账号或者密码错误，服务器端判断返回
           * 3. 密码错误超过5次，锁定2小时
           */
          setLoading(false);
          // 如果接口返回错误是：账号或密码错误，显示为元素报错，其他错误显示为message提示
          if (LOGIN_ERROR_TIP_MSG.includes(res?.msg)) {
            setResError(res?.msg);
          } else {
            message.error(intl[res?.msg] ?? res?.msg);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const title: string = intl["login.text.SAS"];
  const messageEmail: string = intl["register.email.incorrect"];

  return (
    <div className={styles.formSAS}>
      <LoginBaseHandle />
      <div className={styles.container}>
        <p className={styles.title}> {title} </p>
        <ErrorTip errorText={resError || ""} />
        <Form
          name="basic"
          layout="vertical"
          autoComplete="off"
          form={form}
          initialValues={{ accountName: accountName, password: password }}
          onFinish={handleSubmit}>
          <Form.Item
            className={styles.email}
            name="email"
            validateTrigger="onBlur"
            getValueFromEvent={(e) => e.target.value.trim()}
            rules={[
              {
                type: "email",
                message: messageEmail
              },
              {
                required: true,
                message: messageEmail
              }
            ]}>
            <div>
              <label className={clsx(styles.label, accountName ? styles.active : "")}>
                {intl["login.email"]}
              </label>
              <WithTrimInput
                className={clsx(
                  accountName ? styles.inputHover : "",
                  resError && styles.errorFormBorder
                )}
                autoComplete="new-password"
                ref={emailInput}
                name="accountName"
                value={accountName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAccountName(e.target.value)
                }
                onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFields([
                    {
                      name: "email",
                      value: e.target.value,
                      errors: undefined
                    }
                  ]);
                  handleFocus(e);
                }}
                onBlur={handleBlur}
              />
            </div>
          </Form.Item>
          <Form.Item rules={[{ required: true, message: intl["error.password.format"] }]}>
            <div>
              <label className={clsx(styles.label, password ? styles.active : "")}>
                {intl["login.password"]}
              </label>
              <WithTrimInput
                className={clsx(
                  password ? styles.inputHover : "",
                  resError && styles.errorFormBorder
                )}
                name="password"
                type="password"
                autoComplete="new-password"
                maxLength={PASSWORD_MAX_LENGTH}
                defaultValue={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
          </Form.Item>
          <Button
            className={styles.submit}
            type="primary"
            htmlType="submit"
            disabled={!accountName || !password}
            loading={loading}>
            {intl["login"]}
          </Button>
        </Form>
      </div>
    </div>
  );
};
export const PageManagerLogin: React.FC<{}> = React.memo(LoginLayoutSAS(ManagerLogin));
