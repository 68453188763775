import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Breadcrumb, Spin, Select, Button } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import Link from "next/link";
import Image from "next/image";
import Bugsnag from "@bugsnag/js";
import { useRouter } from "next/router";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from "@aspen/theme/Wallet.module.less";
import {
  WALLET_PATHS,
  computeNumber,
  convertUSD2USDC,
  decimalPointNoMoreX,
  formatBugsnagMessage,
  getQueryValue,
  i18nUtil,
  numberToThousands,
  reportEvent,
  scientificToNumber
} from "@aspen/libs";
import {
  IBusinessVerificationHeaderModel,
  IReqSecurityVerifyHeader,
  IBindingBusinessType,
  VAULT_ACTION_TYPE
} from "@aspen/model";
import {
  getVaultAssetCurrencyConfig,
  showVaultDepositAddress,
  vaultWalletDeposit,
  getAssetCurrencyConfig,
  availableSpots
} from "@aspen/services";
import { WithTrimInput, message, NumericInput, WithKYCInfo } from "@aspen/ui";
import { VaultHistory, ModalBusinessVerification, WithRiskReminder } from "@aspen/widgets";

import vaultStorageImg from "@aspen/assets/images/vaultStorage.png";

interface IProps {}

const VaultStorage: React.FC<IProps> = () => {
  const intl = i18nUtil.t();
  const coldWallet = intl["wallet.cold.wallet"];
  const information = intl["wallet.deposit.btc"];
  const select = intl["wallet.deposit.btc.select"];
  const tag = intl["wallet.deposit.btc.tag"];
  const notice = intl["wallet.deposit.btc.notice"];
  const copyText = `${intl["button.copy"]}`;
  const location = intl["kyc.certify.location"];
  const fromExternalWallet = intl["wallet.modal.vault.storage.from.external"];
  const fromSpot = intl["wallet.modal.vault.storage.from.spot"];
  const storageAddress = intl["wallet.vault.storage.address"];
  const storageAmount = intl["wallet.vault.storage.amount"];
  const storageAddressTips = intl["wallet.vault.storage.address.tips"];
  const storage = intl["button.storage"];
  const spotAvailable = intl["wallet.vault.storage.spot.available"];
  const storageSuccess = intl["wallet.vault.storage.success"];
  const storageFailed = intl["wallet.vault.storage.failed"];

  const router = useRouter();
  const type: string = (router?.query?.type as string) ?? getQueryValue("type");
  const currency = router?.query?.currency ?? getQueryValue("currency");
  const [state, setState] = useState<any>({
    loading: false,
    amount: "",
    currentCoin: "",
    currentChain: null,
    coinList: [],
    chainList: [],
    depositAddress: "",
    currentTag: "",
    availableNetwork: 0,
    depositNetwork: 0,
    curAvailableSpotBalance: 0,
    confirmLoading: false,
    chainListFromSpot: {
      withdrawFeeRate: undefined,
      withdrawFeeType: undefined,
      withdrawFeeAmount: undefined
    },
    depositCurrency: "",
    showDepositVerification: false
  });

  const handleSetState = (stateObj) => {
    setState((prevState) => ({ ...prevState, ...stateObj }));
  };

  const getAvailableSpot = (_currency: string) => {
    if (_currency) {
      const param = {
        currency: _currency
      };
      availableSpots(param).then((res) => {
        handleSetState({ curAvailableSpotBalance: res?.data?.[_currency] ?? 0 });
      });
    }
  };

  useEffect(() => {
    getAvailableSpot(state.currentCoin || (currency as string));
  }, [state.currentCoin]);

  useEffect(() => {
    getCoinList();
  }, []);

  // 获取充值币种列表
  const getCoinList: () => void = () => {
    handleSetState({ loading: true });

    getVaultAssetCurrencyConfig({})
      .then((res) => {
        if (res?.code == "0") {
          if (res?.data.length > 0) {
            let currentCoinData = res?.data?.[0]?.currency;

            if (currency) {
              if (res?.data.some((item) => item?.currency == currency)) {
                currentCoinData = currency;
              } else {
                handleSetState({ showTips: true });
              }
            }

            handleSetState({
              currentCoin: currentCoinData,
              coinList: res?.data || []
            });

            fetchChainList(currentCoinData);
          } else {
            Bugsnag.notify(
              new Error(
                formatBugsnagMessage(
                  "no Coin list" + JSON.stringify(res, null, "\t"),
                  "Action Deposit"
                )
              )
            );
          }
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        handleSetState({ loading: false });
      });
  };

  // 获取链地址
  const fetchChainList: (currency: string) => void = (currency) => {
    getVaultAssetCurrencyConfig({ currency, walletType: 1 })
      .then((res) => {
        if (res?.code == 0) {
          const { data } = res;
          const chainList =
            (data && data[0]?.assets?.filter((item) => item.chainType !== "FIAT")) || [];
          const currentChain = chainList[0] || [];
          handleSetState({
            chainList,
            currentChain: currentChain || {},
            availableNetwork: (data && data[0]?.assets?.[0]?.availableNetwork) || 0,
            depositNetwork: (data && data[0]?.assets?.[0]?.depositNetwork) || 0
          });
          showWalletDepositAddress(chainList[0]?.asset);
          fetchAssetList(currency, currentChain);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        handleSetState({
          loading: false
        });
      });
  };

  /**
   * 该请求是为了获取该币种现货提币的手续费配置和currency
   * @param currency
   */
  const fetchAssetList = (currency: string, currentChain) => {
    if (currency) {
      const param = {
        currency: currency
      };
      getAssetCurrencyConfig(param)
        .then((res) => {
          if (res?.code == 0) {
            const chainListHot =
              res.data[0]?.assets?.filter((item) => item.chainType !== "FIAT") || [];
            // 这里从热钱包资产配置中循环找到与冷钱包资产配置同名的链是为了兼容同一币种下冷热配置的链数量和顺序不同的情况
            for (let j = 0; j < chainListHot.length; j++) {
              if (currentChain?.chainName == chainListHot[j]?.chainName) {
                handleSetState({
                  depositCurrency: chainListHot[j]?.asset,
                  chainListFromSpot: chainListHot[j]
                });
                break;
              }
            }
          } else {
            message.error(intl?.[res?.msg] ?? res?.msg);
          }
        })
        .finally(() => {});
    }
  };

  // 先获取已生成电子货币地址 没有则请求生成电子货币地址（多链传入coincode为链的asset）
  const showWalletDepositAddress: (coincode: string) => void = (coincode) => {
    handleSetState({ loading: true });

    const params = {
      currency: coincode,
      required: true
    };

    showVaultDepositAddress(params)
      .then((res) => {
        if (res?.code == "0") {
          if (res?.data?.address) {
            handleSetState({
              depositAddress: res.data.address,
              currentTag: res.data?.memo && res.data?.memo,
              loading: false
            });
          }
        } else {
          handleSetState({
            depositAddress: "",
            loading: false
          });
        }
      })
      .finally(() => {
        handleSetState({ loading: false });
      });
  };

  const confirmInfo = (verifyParam: IBusinessVerificationHeaderModel) => {
    handleSetState({ confirmLoading: true });
    const mergedParam = {
      address: state.depositAddress,
      amount: state.amount,
      currency: state.depositCurrency || "",
      memo: state.currentTag || "",
      outerBusinessId: ""
    };
    let headerParams: IReqSecurityVerifyHeader = {
      businessType: IBindingBusinessType.vaultWalletDeposit,
      smsType: 0
    };
    headerParams = Object.assign({}, headerParams, verifyParam);
    reportEvent({
      moduleName: "vaultStorage",
      detailParams: {
        amount: mergedParam.amount,
        currency: mergedParam.currency,
        type
      }
    });
    vaultWalletDeposit(mergedParam, headerParams)
      .then((res) => {
        if (res?.code == "0") {
          message.success(storageSuccess, 2).then(() => {
            getAvailableSpot(state.currentCoin || (currency as string));
            getCoinList();
            handleSetState({ showDepositVerification: false });
          });
        } else {
          let msg = intl?.[res?.msg];
          if (!msg) {
            msg = intl?.[res?.code] ?? storageFailed;
          }
          message.error(msg);
        }
      })
      .finally(() => {
        handleSetState({ confirmLoading: false });
      });
  };

  const handleChange = (value: string) => {
    reportEvent({
      moduleName: "vaultStorageSelectCoin",
      detailParams: {
        currency: value,
        type
      }
    });
    fetchChainList(value);
    handleSetState({
      depositAddress: "",
      currentCoin: value
    });
  };

  const handleChangeChain = (value: string) => {
    reportEvent({
      moduleName: "vaultStorageSelectChain",
      detailParams: {
        currency: state.currentCoin,
        chain: value,
        type
      }
    });
    fetchAssetList(state.currentCoin, _getChainByAsset(value));
    showWalletDepositAddress(value);
    handleSetState({
      currentChain: _getChainByAsset(value)
    });
  };

  const handleClickCopy = () => {
    message.success(intl["copied"]);
  };
  // 修改金额
  const handleChangeAmount = (e) => {
    const value = e.replace(/^(-)*(\d+)\.(\d{1,8}).*$/, "$1$2.$3"); //八位小数
    handleSetState({ amount: value });
  };
  const checkAmount = () => {
    // 用户输入小于冷钱包的最小充币额时，报错提示：
    if (Number(state.amount) < state.currentChain?.minDepositAmount) {
      return `${intl["wallet.minimum.deposit"]} ${decimalPointNoMoreX(
        state.currentChain?.minDepositAmount
      )}`;
    }
    // 用户输入小于手续费，报错提示：
    if (Number(state.amount) < Number(fee)) {
      return `${intl["wallet.amount.less.fee"]}`;
    }
    // 用户输入超过账户可用余额时，报错提示
    if (Number(state.amount) > state.curAvailableSpotBalance) {
      return `${intl["wallet.exceed.max.spot.available"]} ${decimalPointNoMoreX(
        state?.curAvailableSpotBalance
      )} ${currency}`;
    }

    return false;
  };

  const _getChainByAsset = (asset: string) => {
    const result = state.chainList.filter((item) => item.asset == asset) || [];
    return result[0];
  };

  /**
   * 计算输入金额费率
   * spot转入时手续费配置同该币种现货提币的手续费配置
   */
  const fee = useMemo(() => {
    let count: number = 0;
    const feeType = state.chainListFromSpot?.withdrawFeeType;
    const feeRate = state.chainListFromSpot?.withdrawFeeRate;
    const feeAmount = state.chainListFromSpot?.withdrawFeeAmount;
    switch (feeType) {
      case "PERCENTAGE":
        count = computeNumber(+state.amount, "*", feeRate).result;
        break;
      case "FIXED":
        count = +feeAmount;
        break;
      case "MIXED":
        count = Math.max(computeNumber(+state.amount, "*", feeRate).result, +feeAmount);
        break;
    }

    return count?.toFixed(8);
  }, [state.currentChain, state.amount]);

  return (
    <section className={styles.vaultStorage}>
      <Spin spinning={state.loading}>
        <Breadcrumb>
          <Breadcrumb.Item>{location}:</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link href={WALLET_PATHS.WALLET_VAULT}>{coldWallet}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{information}</Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.depositContent}>
          <p className={styles.title}>{intl["wallet.cold.storage"]}</p>
          <div className={styles.vaultTypeContent}>
            <span className={styles.img}>
              <Image alt="" src={vaultStorageImg} width={24} height={24} />
            </span>
            <span className={styles.text}>{type === "spot" ? fromSpot : fromExternalWallet}</span>
          </div>
          <Row>
            <Col flex="auto" className={styles.depositLeft}>
              <div className={styles.currentCoin}>
                <div className={styles.currentCoinLeft}>
                  <p className={styles.label}>{select}</p>
                  <Select
                    className={styles.selectCoin}
                    value={state.currentCoin}
                    style={{ width: 160 }}
                    onChange={handleChange}>
                    {state.coinList.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.currency}>
                          {convertUSD2USDC(item.currency)}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                <div className={styles.currentCoinRight}>
                  <p className={styles.label}>{intl["wallet.deposit.btc.select.network"]}</p>
                  <Select
                    className={styles.selectCoin}
                    value={state.currentChain?.chainName}
                    style={{ width: 160 }}
                    onChange={handleChangeChain}>
                    {state.chainList.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.asset}>
                          {item.chainName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              {/* Spot 输入需要存储的金额 */}
              {type === "spot" ? (
                <div className={styles.amountInputContainer} style={{ marginBottom: 32 }}>
                  <p className={styles.label}>{storageAmount}</p>
                  <NumericInput
                    bordered={false}
                    value={state.amount}
                    placeholder={`${spotAvailable} ${numberToThousands(
                      state.curAvailableSpotBalance
                    )}`}
                    className={styles.amountInput}
                    onChangeEvent={handleChangeAmount}
                  />
                  {
                    <div className={styles.amountTipContent}>
                      {state.amount != "" && !checkAmount() && (
                        <p className={styles.feeTips}>
                          {i18nUtil.formatMessage(
                            { id: "wallet.withdraw.fee" },
                            {
                              fee: fee
                            }
                          )}
                          &nbsp;{convertUSD2USDC(state.currentCoin)}
                        </p>
                      )}
                      {state.amount != "" && checkAmount() && (
                        <p className={styles.limitTips}>{checkAmount()}</p>
                      )}
                    </div>
                  }
                </div>
              ) : (
                <div style={{ marginBottom: 32 }}>
                  <p className={styles.label}>{intl["wallet.minimum.deposit"]}</p>
                  <p className={styles.minDepositAmount}>
                    {state.currentChain?.minDepositAmount
                      ? `${scientificToNumber(
                          state.currentChain?.minDepositAmount
                        )} ${convertUSD2USDC(state.currentCoin)}`
                      : "--"}
                  </p>
                </div>
              )}
              {/* 充值地址 */}
              <p className={styles.label}>{storageAddress}</p>
              <div className={styles.copyArea}>
                <WithTrimInput type="text" value={state.depositAddress} disabled />
                {type === "external" && (
                  <CopyToClipboard text={state.depositAddress} onCopy={handleClickCopy}>
                    <span>{copyText}</span>
                  </CopyToClipboard>
                )}
              </div>
              {state.currentCoin == "XRP" && (
                <div>
                  <p className={styles.label}>{state.currentCoin + tag}</p>
                  <div className={styles.copyArea}>
                    <WithTrimInput type="text" value={state.currentTag} disabled />
                    <CopyToClipboard text={state.currentTag} onCopy={handleClickCopy}>
                      <span>{copyText}</span>
                    </CopyToClipboard>
                  </div>
                </div>
              )}
              {type === "external" && (
                <div>
                  <InfoCircleFilled
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      color: "#ff5351",
                      marginRight: 10
                    }}
                  />
                  <span className={styles.tips}>{storageAddressTips}</span>
                </div>
              )}
              {type === "spot" && (
                <Button
                  style={{ marginTop: 16 }}
                  disabled={!(state.amount && !checkAmount())}
                  type="primary"
                  onClick={() => {
                    handleSetState({ showDepositVerification: true });
                  }}>
                  {storage}
                </Button>
              )}
            </Col>
            {type === "external" && (
              <Col flex="300px" className={styles.depositRight}>
                <p className={styles.label}>
                  {i18nUtil.formatMessage(
                    { id: "wallet.vault.qrcode.tips" },
                    {
                      currentCoin: convertUSD2USDC(state.currentCoin)
                    }
                  )}
                </p>
                <div className={styles.QRCode}>
                  <QRCode value={state.depositAddress} size={170} />
                </div>
              </Col>
            )}
          </Row>
          {/* Notice */}
          <div className={styles.noticeContent}>
            <p className={styles.title}>{notice}</p>
            <p>
              {i18nUtil.formatMessage(
                { id: "wallet.vault.storage.notice.first" },
                {
                  currency: convertUSD2USDC(state.currentCoin)
                }
              )}
            </p>
            <p>
              {i18nUtil.formatMessage(
                { id: "wallet.vault.storage.notice.second" },
                {
                  depositNetwork: state.chainList?.[0]?.depositNetwork || 0,
                  availableNetwork: state.chainList?.[0]?.availableNetwork || 0
                }
              )}
            </p>
            <p>{intl["wallet.vault.storage.notice.third"]}</p>
          </div>
        </div>
        {state.showDepositVerification ? (
          <ModalBusinessVerification
            confirmLoading={state.confirmLoading}
            visible={state.showDepositVerification}
            businessType={IBindingBusinessType.vaultWalletDeposit}
            confirm={(res) => {
              confirmInfo(res);
            }}
            cancel={() => {
              handleSetState({ showDepositVerification: false });
            }}
          />
        ) : null}
      </Spin>

      <VaultHistory
        showViewAll={true}
        type={VAULT_ACTION_TYPE.STORAGE}
        storageType={type}
        currency={state.currentCoin}
      />
    </section>
  );
};

export const PageVaultStorage = WithKYCInfo(WithRiskReminder(React.memo(VaultStorage)));
