import React from "react";
import { Col, Divider, Row, Tabs } from "antd";
import styles from "@aspen/theme/Structured.module.less";
import { IInjectProps } from "@aspen/model";
import { withRouter } from "next/router";
import {
  getComplianceTag,
  getQueryValue,
  i18nUtil,
  reportEvent,
  saveComplianceTag,
  GA_EVENT_NAME,
  STRUCTURED_TYPES,
  USER_AUTH,
  USER_ROLE,
  isClient
} from "@aspen/libs";
import { Disclaimer, Compliance, ModalCompliance } from "@aspen/ui";
import { StructuredTableWrapper, AccumulatorTab, TabLabel, SnowballTab } from "@aspen/widgets";

interface IProps extends IInjectProps {}

interface IState {
  currentTab: string;
  showCompliance: boolean;
}
const intl = i18nUtil.t();
const user_auth = (isClient && window.localStorage.getItem(USER_AUTH)) || USER_ROLE.ROLE_CUSTOMER;
const roles = [USER_ROLE.ROLE_SUB, USER_ROLE.ROLE_PARTNER_CUSTOMER];

const isSubOrPartner = roles.includes(user_auth);

class Structured extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentTab: "",
      showCompliance: false
    };
  }

  // 子账号，代理商不展示双币、累计期权
  tabItems = [
    !isSubOrPartner && {
      key: STRUCTURED_TYPES.dual,
      label: (
        <TabLabel
          title={"wallet.structural.dual.currency"}
          tooltipDesc={"structured.tab.tooltips"}
        />
      )
    },
    !isSubOrPartner && {
      key: STRUCTURED_TYPES.accumulator,
      label: (
        <TabLabel
          title={"wallet.structural.accumulator"}
          tooltipDesc={"wallet.structural.accumulator.tooltips"}
        />
      )
    },
    {
      key: STRUCTURED_TYPES.snowball,
      label: (
        <TabLabel
          title={"wallet.structural.snowball"}
          tooltipDesc={"wallet.structural.snowball.tooltips"}
        />
      )
    }
  ];

  componentDidMount() {
    const { router } = this.props;

    // 子账号、代理商不支持双币，默认显示雪球
    const defaultTabByRole = isSubOrPartner ? STRUCTURED_TYPES.snowball : STRUCTURED_TYPES.dual;
    const defaultTab =
      this.props?.router?.query?.currentTab?.toString() ||
      (isClient && getQueryValue("currentTab")) ||
      defaultTabByRole;
    this.setState({
      currentTab: defaultTab
    });

    let tag = getComplianceTag(router.pathname);
    if (!tag) {
      this._showCompliance(true);
    }
  }

  handleChangeTab = (currentTab) => {
    reportEvent({
      moduleName: GA_EVENT_NAME.structured.switchStructureList,
      detailParams: { tab: currentTab }
    });
    this.props.router.replace({ query: { currentTab } });
    this.setState({
      currentTab
    });
  };
  render(): React.ReactNode {
    const { currentTab, showCompliance } = this.state;
    return (
      <section className={styles.structured + " customer"}>
        <div className="customerTop">
          <Row className="top">
            <Col span={24}>
              <p className="title">{intl["structured.location.structured"]}</p>
            </Col>
          </Row>
        </div>
        <Row className="section">
          <Col span={24}>
            <Tabs
              // @ts-ignore
              items={this.tabItems}
              onChange={this.handleChangeTab}
              className={styles.tab}
              activeKey={currentTab}
            />
            <div className={styles.list}>
              <div className={styles.flexibleContent}>
                {currentTab === STRUCTURED_TYPES.dual && <StructuredTableWrapper />}
                {currentTab === STRUCTURED_TYPES.accumulator && <AccumulatorTab />}
                {currentTab === STRUCTURED_TYPES.snowball && <SnowballTab />}
              </div>
            </div>
          </Col>
        </Row>
        <Compliance module="structured" />
        <Divider />
        <Disclaimer />
        {showCompliance ? (
          <ModalCompliance
            open={showCompliance}
            onConfirm={this._onComplianceOk}
            onHide={this._onComplianceCancel}
          />
        ) : undefined}
      </section>
    );
  }
  _showCompliance = (show: boolean) => {
    this.setState({ showCompliance: show });
  };
  _onComplianceOk = () => {
    const { router } = this.props;
    saveComplianceTag(router.pathname);
    this._showCompliance(false);
  };
  _onComplianceCancel = () => {
    const { router } = this.props;
    this._showCompliance(false);
    router.back();
  };
}

export const PageStructured = withRouter(Structured);
