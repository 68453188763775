import React, { useEffect, useMemo, useState } from "react";
import { Divider, Empty, Spin } from "antd";
import {
  IVentureUpdatesDetail,
  IReqVenturesUpdatesDetail,
  IReqVenturesUpdates,
  IVentureUpdatesItem
} from "@aspen/model";
import {
  getWalletVentureUpdates,
  getWalletVentureUpdatesDetail,
  walletVentureUpdatesRead
} from "@aspen/services";
import styles from "@aspen/theme/Wallet.module.less";
import { getQueryValue, i18nUtil, transformTime, VENTURES_PATHS, WALLET_PATHS } from "@aspen/libs";
import Image from "next/image";
import { useRouter } from "next/router";
import { DownloadAppCard } from "@aspen/widgets";
import { HtmlContentComponent } from "@aspen/ui";
const noAuth = require("@aspen/assets/images/no_authz_web.webp").default;
interface IProps {}

const VentureUpdatesDetail: React.FC<IProps> = (props) => {
  const intl = i18nUtil.t();
  const router = useRouter();
  const updatesId = router.query?.id?.toString() ?? getQueryValue("id");
  const productId = router.query?.productId?.toString() ?? getQueryValue("productId");

  const [data, setData] = useState<IVentureUpdatesDetail>();
  const [list, setList] = useState<IVentureUpdatesItem[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isAuth, setIsAuth] = useState<boolean>(true);
  const ventureInfo = data?.ventureInfoList?.[0];

  useEffect(() => {
    fetchData();
    fetchListData();
    disableImageContextMenu();
  }, [updatesId]);

  // 禁用图片右键功能栏
  const disableImageContextMenu = () => {
    document.addEventListener("contextmenu", function (e) {
      const element = e?.target as Node;
      if (element?.nodeName === "IMG") {
        e.preventDefault();
      }
    });
  };

  const fetchData = () => {
    // 处理邮件链接跳转存在app路由参数的问题
    const id = updatesId.includes("?") ? updatesId.split("?")[0] : updatesId;
    const params: IReqVenturesUpdatesDetail = {
      updatesId: id,
      productId
    };
    getWalletVentureUpdatesDetail(params)
      .then((res) => {
        if (res?.code == "0") {
          setData(res?.data);
        } else if(res?.code == "5205"){
          setIsAuth(false)
        }
      })
      .finally(() => {
        setLoading(false);
        walletVentureUpdatesRead({ id: updatesId });
      });
  };

  const fetchListData = () => {
    const params: IReqVenturesUpdates = {
      productId: productId,
      limit: 6
    };
    getWalletVentureUpdates(params).then((res) => {
      if (res?.code == "0") {
        const resData = res?.data?.rows ?? [];
        const newList = resData?.filter((e) => e.id !== updatesId)?.slice(0, 5);
        setList(newList);
      }
    });
  };

  const purchaseInformation = useMemo(() => {
    try {
      const info = JSON.parse(ventureInfo?.purchaseInformation ?? "");
      return info;
    } catch (e) {
      return [];
    }
  }, [ventureInfo?.purchaseInformation]);

  const handleClickVenture = (id?: string) => {
    router.push({
      pathname: `${VENTURES_PATHS.VENTURES_DETAIL}/${id ? id : productId}`
    });
  };

  return (
    <Spin spinning={loading}>
      {isAuth ? <div className={styles.ventureUpdatesDetail}>
        <div className={styles.detailContent}>
          <div className={styles.detailTitle}>{data?.title}</div>
          <div className={styles.detailSubTitle}>
            <span className={styles.detailTime}>{transformTime(data?.publishTime)}</span>

            {productId ? (
              <span className={styles.detailTag} onClick={() => handleClickVenture(productId)}>
                {ventureInfo?.name}
              </span>
            ) : (
              data?.ventureInfoList.map((item) => {
                return (
                  <span className={styles.detailTag} onClick={() => handleClickVenture(item?.id)}>
                    {item?.name}
                  </span>
                );
              })
            )}
          </div>
          <HtmlContentComponent htmlContent={data?.details ?? ""} />
        </div>
        <div className={styles.rightContent}>
          <div className={styles.ventureInfo} onClick={() => handleClickVenture()}>
            <div className={styles.cardTitle}>{intl["client.venture"]}</div>
            <Divider />
            {ventureInfo?.listPictureOnSaleAddressWeb && (
              <Image
                className={styles.image}
                alt=""
                unoptimized
                src={ventureInfo?.listPictureOnSaleAddressWeb}
                width={305}
                height={133}
              />
            )}
            {purchaseInformation.map((item) => (
              <div key={item?.title} className={styles.propItemBox}>
                <div title={item?.title} className={styles.itemTitle}>
                  {item?.title}
                </div>
                <div title={item?.subTitle} className={styles.itemValue}>
                  {item?.subTitle}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.updateslist}>
            <div className={styles.cardTitle}>{intl["latestUpdates"]}</div>
            <Divider />
            {list?.length ? (
              list.map((item) => {
                return (
                  <div
                    key={item?.id}
                    className={styles.updatesListItem}
                    onClick={() => {
                      router.replace({
                        pathname: WALLET_PATHS.WALLET_VENTURE_UPDATES_DETAIL,
                        query: { id: item.id, productId }
                      });
                    }}>
                    <div className={styles.updatesListItemTitle}>{item?.title}</div>
                    <div className={styles.updatesListItemTime}>
                      {transformTime(item?.publishTime)}
                    </div>
                  </div>
                );
              })
            ) : (
              <div style={{ textAlign: "center" }}>{intl["noMoreData"]} </div>
            )}
          </div>
          <div className={styles.appDownload}>
            <DownloadAppCard />
          </div>
        </div>
      </div> :  <div className={styles.emptyContainerWrapper}>
        <Empty image={<Image
          unoptimized
          alt=""
          src={noAuth}
          width={110}
          height={140}
        />} description={intl["no.auth.view"]}/>
      </div>}
    </Spin>
  );
};

export const PageVentureUpdatesDetail = VentureUpdatesDetail;
